import React from "react";
import PropTypes from "prop-types";
import { PG_AVJS_FORM_ONE_CONTENT } from "../../common/Forms/SectionForm.content";
import StandardEmailCollectContainer from "../../../containers/common/forms/StandardEmailCollectContainer";
import GetScreen from "../GetScreen";
import "./index.scss";

const FormOne = ({
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
  page,
}) => {
  return (
    <section className="PgAVJS-FormOne">
      <div className="container">
        <div className="PgAVJS-FormOne__wrap">
          <div className="PgAVJS-FormOne__wrapForm">
            <StandardEmailCollectContainer
              pageFormState={isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              page={page}
              buttonTextFirstState="Try AMZScout for free!"
              buttonTextSecondState="Try AMZScout for free!"
              title="Try AMZScout for FREE right now!"
              placeholderText="example@gmail.com"
              isNewForm
              staticTopTitle="Try AMZScout for FREE right now!"
              customClass="PgAVJS-FormOne__emailCollect"
            />
          </div>
          <GetScreen
            classes="PgAVJS-FormOne__img"
            listPictures={PG_AVJS_FORM_ONE_CONTENT}
            isSectionForm
          />
        </div>
      </div>
    </section>
  );
};
FormOne.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  page: PropTypes.string,
};

export default FormOne;
